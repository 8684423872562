import React, { useState } from "react";
import "../../styles/ScenariHR.css";

const HCFilters = ({ equipes, onApplyFilters }) => {
    const [isVisible, setIsVisible] = useState(false); // Contrôle la visibilité des filtres
    const [selectedFilter, setSelectedFilter] = useState("");
    const [filterValues, setFilterValues] = useState({
        status: "",
        teamId: "",
        startDate: "",
        endDate: "",
        salaryMin: "",
        salaryMax: "",
    });
    const [appliedFilters, setAppliedFilters] = useState([]);

    const filterOptions = [
        { value: "status", label: "Status" },
        { value: "team", label: "Team" },
        { value: "dateRange", label: "Date Range" },
        { value: "salaryRange", label: "Salary Range" },
    ];

    const handleFilterChange = (e) => {
        setSelectedFilter(e.target.value);
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setFilterValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleApplyFilters = () => {
        onApplyFilters(filterValues); // Passe les critères au parent
        setAppliedFilters(
            Object.entries(filterValues)
                .filter(([key, value]) => value)
                .map(([key, value]) => ({ key, value }))
        ); // Stocke les filtres appliqués
        setIsVisible(false); // Masque les filtres après application
    };

    const resetFilters = () => {
        setFilterValues({
            status: "",
            teamId: "",
            startDate: "",
            endDate: "",
            salaryMin: "",
            salaryMax: "",
        });
        setAppliedFilters([]);
        onApplyFilters({}); // Réinitialise les filtres au parent
        setIsVisible(false);
    };

    const removeFilter = (key) => {
        setFilterValues((prev) => ({ ...prev, [key]: "" })); // Réinitialise la valeur du filtre
        setAppliedFilters((prev) => prev.filter((filter) => filter.key !== key)); // Retire des tags
        onApplyFilters({
            ...filterValues,
            [key]: "", // Supprime l'effet du filtre
        });
    };

    return (
        <div >
            <button className="btn-secondary" onClick={() => setIsVisible((prev) => !prev)}>
                {isVisible ? "Hide Filters" : "Filter By"}
            </button>

            {appliedFilters.length > 0 && (
                <div className="applied-filters">
                    {appliedFilters.map((filter) => (
                        <div key={filter.key} className="filter-tag">
                            <span>{`${filter.key}: ${filter.value}`}</span>
                            <button onClick={() => removeFilter(filter.key)}>&times;</button>
                        </div>
                    ))}
                </div>
            )}

            {isVisible && (
                <div className="filters-dropdown">
                    <div className="filter-section">
                        <label htmlFor="filter-select">Select a filter type:</label>
                        <select
                            id="filter-select"
                            value={selectedFilter}
                            onChange={handleFilterChange}
                        >
                            <option value="">-- Choose a filter --</option>
                            {filterOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    {selectedFilter === "status" && (
                        <div className="filter-section">
                            <label htmlFor="status-select">Status:</label>
                            <select
                                id="status-select"
                                name="status"
                                value={filterValues.status}
                                onChange={handleValueChange}
                            >
                                <option value="">Select a status</option>
                                <option value="PLANNED">Planned</option>
                                <option value="APPROVED">Approved</option>
                                <option value="CANCELLED">Cancelled</option>
                            </select>
                        </div>
                    )}

                    {selectedFilter === "team" && (
                        <div className="filter-section">
                            <label htmlFor="team-select">Team:</label>
                            <select
                                id="team-select"
                                name="teamId"
                                value={filterValues.teamId}
                                onChange={handleValueChange}
                            >
                                <option value="">Select a team</option>
                                {equipes.map((team) => (
                                    <option key={team.id} value={team.nomEquipe}>
                                        {team.nomEquipe}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {selectedFilter === "dateRange" && (
                        <div className="filter-section">
                            <label>Start Date:</label>
                            <input
                                type="date"
                                name="startDate"
                                value={filterValues.startDate}
                                onChange={handleValueChange}
                            />
                            <label>End Date:</label>
                            <input
                                type="date"
                                name="endDate"
                                value={filterValues.endDate}
                                onChange={handleValueChange}
                            />
                        </div>
                    )}

                    {selectedFilter === "salaryRange" && (
                        <div className="filter-section">
                            <label>Min Salary:</label>
                            <input
                                type="number"
                                name="salaryMin"
                                value={filterValues.salaryMin}
                                onChange={handleValueChange}
                            />
                            <label>Max Salary:</label>
                            <input
                                type="number"
                                name="salaryMax"
                                value={filterValues.salaryMax}
                                onChange={handleValueChange}
                            />
                        </div>
                    )}

                    <div className="filter-actions">
                        <button  onClick={handleApplyFilters} className="apply-button">
                            Apply Filters
                        </button>
                        <button onClick={resetFilters} className="reset-button">
                            Reset Filters
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HCFilters;
