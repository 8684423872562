import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, BarElement, LineElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import "../../styles/Chart.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Tooltip, Legend);

const GraphMonthlyView = ({ scenarioId }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });
    const [error, setError] = useState(null);

    const barColor = '#577590';
    const lineColor = '#f3722c';

    useEffect(() => {
        const fetchChartData = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const query = `
                query ($scenarioId: Int!, $startDate: String!, $endDate: String!) {
                    futureOpenPosition(scenarioId: $scenarioId) {
                        arrivalMonth
                        status
                    }
                    masseSalarialeChargeeParMois(scenarioId: $scenarioId, startDate: $startDate, endDate: $endDate) {
                        month
                        salary
                    }
                }
            `;
            const variables = {
                scenarioId: parseInt(scenarioId, 10),
                startDate: '2025-01-01',
                endDate: '2025-12-31',
            };

            try {
                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Error: ${responseData.errors[0].message}`);
                    return;
                }

                const futureOpenPositions = responseData.data.futureOpenPosition || [];
                const salaryData = responseData.data.masseSalarialeChargeeParMois || [];
                processChartData(futureOpenPositions, salaryData);
            } catch (error) {
                setError(`Error fetching data: ${error.message}`);
            }
        };

        fetchChartData();
    }, [scenarioId]);

    const processChartData = (positions, salaryData) => {
        // Filtrer les positions confirmées
        const confirmedPositions = positions.filter(pos => pos.status === 'APPROVED');
        console.log('Confirmed Positions:', confirmedPositions);

        // Grouper les FOP par mois (MM-YYYY)
        const groupedByMonth = confirmedPositions.reduce((acc, pos) => {
            const date = new Date(pos.arrivalMonth);
            const monthYear = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
            acc[monthYear] = (acc[monthYear] || 0) + 1;
            return acc;
        }, {});

        // Trier les mois

        // Sort the months correctly by converting 'MM-YYYY' to 'YYYY-MM-01'
        const labels = [...new Set([...Object.keys(groupedByMonth), ...salaryData.map(s => s.month)])].sort((a, b) => {
            const [monthA, yearA] = a.split('-');
            const [monthB, yearB] = b.split('-');
            const dateA = new Date(`${yearA}-${monthA}-01`);
            const dateB = new Date(`${yearB}-${monthB}-01`);
            return dateA - dateB;
        });


        // Préparer les données pour le graphique
        const barData = labels.map(label => groupedByMonth[label] || 0);
        const lineData = labels.map(label => {
            const salaryEntry = salaryData.find(s => s.month === label);
            return salaryEntry ? parseFloat(salaryEntry.salary) : 0;
        });

        setChartData({
            labels,
            datasets: [
                {
                    label: 'Charged Salary (€)',
                    data: lineData,
                    type: 'line',
                    borderColor: lineColor,
                    borderWidth: 2,
                    fill: false,
                    yAxisID: 'y1',
                },
                {
                    label: 'Number of Confirmed FOPs',
                    data: barData,
                    type: 'bar',
                    backgroundColor: barColor,
                    borderColor: barColor,
                    borderWidth: 1,
                    yAxisID: 'y',
                },

            ],
        });
    };

    const chartOptions = {
        maintainAspectRatio: false, // Désactive le ratio par défaut
        responsive: true, // Rend le graphique réactif
        scales: {
            x: {
                title: { display: true, text: 'Month (MM-YYYY)' }
            },
            y: {
                title: { display: true, text: 'Number of Confirmed FOPs' },
                position: 'left',
                ticks: {
                    beginAtZero: true
                }
            },
            y1: {
                title: { display: true, text: 'Salary (€)' },
                position: 'right',
                grid: {
                    drawOnChartArea: false
                },
                ticks: {
                    beginAtZero: true
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top'
            }
        }
    };

    if (error) return <div>{error}</div>;

    return (
        <div className="hc-container-chart">
           

            <Bar data={chartData} options={chartOptions} />



                

        </div>
    );
};

export default GraphMonthlyView;
