import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Home.css";
import "../../styles/Card.css";
import Tableau from "../../components/recrutement/Tableau";

const DBEmploye = () => {
    const [db, setDb] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState([
        'first_name', 'last_name', 'equipe', 'localisation'
    ]);
    const [sortConfig, setSortConfig] = useState(null);
    const [date1, setDate1] = useState(new Date().toISOString().split('T')[0]); // Date 1 par défaut
    const [date2, setDate2] = useState(new Date().toISOString().split('T')[0]); // Date 2 par défaut
    const [employeesInParisAtDate1, setEmployeesInParisAtDate1] = useState([]);
    const [employeesNotInParisAtDate2, setEmployeesNotInParisAtDate2] = useState([]);

    const fetchParisComparison = useCallback(async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = localStorage.getItem('userData');

            if (!userData) {
                console.error("Les données utilisateur n'ont pas été trouvées dans localStorage.");
                return;
            }

            const parsedUserData = JSON.parse(userData);
            const entrepriseId = parsedUserData.entreprise_id;

            // Récupérer les employés à Paris à la date 1
            const response1 = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: `
                        query ($dateToCheck: Date!, $entrepriseId: Int!) {
                            historiquesEmploi(dateToCheck: $dateToCheck, entrepriseId: $entrepriseId) {
                                employe {
                                    firstName
                                    lastName
                                }
                                localisation
                            }
                        }
                    `,
                    variables: { dateToCheck: date1, entrepriseId: parseInt(entrepriseId, 10) },
                })
            });

            const { data: data1 } = await response1.json();
            const employeesParisAtDate1 = data1.historiquesEmploi.filter(historique => historique.localisation === 'Paris');
            setEmployeesInParisAtDate1(employeesParisAtDate1);

            // Récupérer les employés à Paris à la date 2
            const response2 = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: `
                        query ($dateToCheck: Date!, $entrepriseId: Int!) {
                            historiquesEmploi(dateToCheck: $dateToCheck, entrepriseId: $entrepriseId) {
                                employe {
                                    firstName
                                    lastName
                                }
                                localisation
                            }
                        }
                    `,
                    variables: { dateToCheck: date2, entrepriseId: parseInt(entrepriseId, 10) },
                })
            });

            const { data: data2 } = await response2.json();
            const employeesParisAtDate2 = data2.historiquesEmploi.filter(historique => historique.localisation === 'Paris');

            // Trouver les employés présents à Paris à date1 mais pas à date2
            const employeesNotInParisAtDate2List = employeesParisAtDate1.filter(emp1 =>
                !employeesParisAtDate2.some(emp2 => emp1.employe.firstName === emp2.employe.firstName && emp1.employe.lastName === emp2.employe.lastName)
            );
            setEmployeesNotInParisAtDate2(employeesNotInParisAtDate2List);

        } catch (error) {
            console.error("Erreur lors de la récupération des données:", error);
        }
    }, [date1, date2]);

    useEffect(() => {
        fetchParisComparison();
    }, [fetchParisComparison]);

    const handleDateChange1 = (event) => {
        setDate1(event.target.value); // Met à jour la date 1
    };

    const handleDateChange2 = (event) => {
        setDate2(event.target.value); // Met à jour la date 2
    };

    const allColumns = [
        { key: 'first_name', label: 'Prénom' },
        { key: 'last_name', label: 'Nom' },
        { key: 'equipe', label: 'Équipe' },
        { key: 'localisation', label: 'Localisation' },
    ];

    const handleVisibleColumnsChange = (newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    };

    const handleSort = (sortConfig) => {
        setSortConfig(sortConfig);
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className='line-container-10'></div>
                <div className='cards-container'>
                    <div style={{ marginBottom: '20px' }}>
                        <label htmlFor="date1">Sélectionnez la date 1 : </label>
                        <input
                            id="date1"
                            type="date"
                            value={date1}
                            onChange={handleDateChange1}
                            style={{ marginLeft: '10px', padding: '5px' }}
                        />
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <label htmlFor="date2">Sélectionnez la date 2 : </label>
                        <input
                            id="date2"
                            type="date"
                            value={date2}
                            onChange={handleDateChange2}
                            style={{ marginLeft: '10px', padding: '5px' }}
                        />
                    </div>
                    {employeesNotInParisAtDate2.length === 0 ? (
                        <p>Aucun employé trouvé à Paris à la date 1, mais pas à la date 2.</p>
                    ) : (
                        <Tableau
                            data={employeesNotInParisAtDate2}
                            columns={allColumns}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={handleVisibleColumnsChange}
                            sortConfig={sortConfig}
                            onSort={handleSort}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DBEmploye;
