import React from 'react';
import { Routes, Route } from 'react-router-dom';
import GraphTurnoverRate from '../../pages/graph_pages/GraphTurnoverRate';
import GraphEffectifVolume from '../../pages/graph_pages/GraphEffectifVolume';
import GraphIndexHF from '../../pages/graph_pages/diversity/GraphIndexHF';


const RoutesGraph = () => (
  <Routes>
        <Route path="turnoverrate" element={<GraphTurnoverRate/>}/>
        <Route path="effectifvolume" element={<GraphEffectifVolume/>}/>

        <Route path="indexhf" element={<GraphIndexHF/>}/>
  </Routes>
);

export default RoutesGraph;
