import React, { useRef } from 'react';
import "../../styles/ScenariHR.css";

const FuturePositionForm = ({
    isEditMode,
    formData,
    equipes,
    onInputChange,
    onSubmit,
    onClose,
}) => {
    const modalRef = useRef(null);

    // Liste des statuts selon votre backend
    const statuses = [
        { value: "PLANNED", label: "Planned" },
        { value: "APPROVED", label: "Approved" },
        { value: "CANCELLED", label: "Cancelled" },
    ];

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleClickOutside}>
            <div className="modal-container" ref={modalRef}>
                <button className="modal-close" onClick={onClose}>
                    &times;
                </button>
                <h5>{isEditMode ? 'Update' : 'Create'} Future Open Position</h5>
                <form className='hc-form' onSubmit={onSubmit}>
                    <div className='hc-form-line'>
                        <label>Job Title</label>
                        <input
                            type="text"
                            name="job"
                            value={formData.job || ""}
                            onChange={onInputChange}
                            required
                        />
                    </div>
                    <div className='hc-form-line'>
                        <label>Salary Charged</label>
                        <input
                            type="number"
                            step="0.01"
                            name="salaryCharged"
                            value={formData.salaryCharged || ""}
                            onChange={onInputChange}
                            required
                        />
                    </div>
                    <div className='hc-form-line'>
                        <label>Arrival Month</label>
                        <input
                            type="date"
                            name="arrivalMonth"
                            value={formData.arrivalMonth || ""}
                            onChange={onInputChange}
                            required
                        />
                    </div>
                    <div className='hc-form-line'>
                        <label>Team</label>
                        <select
                            name="teamId"
                            value={formData.teamId || ""}
                            onChange={onInputChange}
                            required
                        >
                            <option value="">Select a team</option>
                            {equipes.map((equipe) => (
                                <option key={equipe.id} value={equipe.id}>
                                    {equipe.nomEquipe}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='hc-form-line'>
                        <label>Status</label>
                        <select
                            name="status"
                            value={formData.status || ""}
                            onChange={onInputChange}
                            required
                        >
                            <option value="">Select a status</option>
                            {statuses.map((status) => (
                                <option key={status.value} value={status.value}>
                                    {status.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='hc-form-line'>
                        <label>Justification</label>
                        <textarea
                            name="justification"
                            value={formData.justification || ""}
                            onChange={onInputChange}
                            rows="4"
                            placeholder="Explain why this recruitment is needed..."
                        ></textarea>
                    </div>

                    <button type="submit">{isEditMode ? 'Update' : 'Create'}</button>
                </form>
            </div>
        </div>
    );
};

export default FuturePositionForm;
