import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import HCForm from '../../../components/hc/HCForm';
import ScenariosTable from '../../../components/hc/ScenariosTable';

const HCScenario = () => {
    const [scenarios, setScenarios] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        budget: '',
        reuseOpenPositions: false,
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false); // Nouveau : indique si on édite un scénario
    const [editingScenarioId, setEditingScenarioId] = useState(null);

    useEffect(() => {
        fetchScenarios();
    }, []);

    const fetchScenarios = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const graphqlUrl = `${apiUrl}/graphql/`;
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('userData'));
        const entrepriseId = userData?.entreprise_id;

        if (!entrepriseId) {
            console.error('Entreprise ID not found in userData');
            return;
        }

        const query = `
            query ($entrepriseId: Int!) {
                scenarios(entrepriseId: $entrepriseId) {
                    id
                    name
                    futureOpenPositionsCount
                    budget
                    startDate
                    endDate
                    status
                }
            }
        `;

        const variables = { entrepriseId };

        try {
            const response = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, variables }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.errors) {
                console.error('GraphQL errors:', responseData.errors);
                return;
            }

            setScenarios(responseData.data.scenarios);
        } catch (error) {
            console.error('Error fetching scenarios:', error);
        }
    };


    const fetchAndCreateFutureOpenPositions = async (scenarioId, entrepriseId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const graphqlUrl = `${apiUrl}/graphql/`;
        const token = localStorage.getItem('token');

        const query = `
            query GetOpenPositions($entrepriseId: Int!) {
                openPositions(entrepriseId: $entrepriseId) {
                    id
                    job
                    team {
                        id
                    }
                    contactManager {
                        id
                    }
                    salaryMax
                    arrivalMonth
                }
            }
        `;

        const variables = { entrepriseId };

        try {
            // Fetch the open positions
            const response = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, variables }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.errors) {
                console.error('GraphQL errors:', responseData.errors);
                return;
            }

            // Convert open positions data to JSON strings
            const openPositionsData = responseData.data.openPositions.map(position => JSON.stringify({
                job: position.job,
                entreprise: entrepriseId,
                team: position.team?.id || null,
                contact_manager: position.contactManager?.id || null,
                salary_max: position.salaryMax || null,
                arrival_month: position.arrivalMonth || null,
            }));

            const mutation = `
                mutation CreateListFutureOpenPosition($scenarioId: Int!, $openPositionsData: [JSONString!]!) {
                    createListFutureOpenPosition(scenarioId: $scenarioId, openPositionsData: $openPositionsData) {
                        futureOpenPositions {
                            id
                            job
                            team {
                                id
                            }
                            contactManager {
                                id
                            }
                            salaryCharged
                            arrivalMonth
                        }
                    }
                }
            `;

            const mutationVariables = {
                scenarioId: parseInt(scenarioId, 10), // Ensure scenarioId is an integer
                openPositionsData,
            };

            const mutationResponse = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables: mutationVariables }),
            });

            if (!mutationResponse.ok) {
                throw new Error(`HTTP error! Status: ${mutationResponse.status}`);
            }

            const mutationResponseData = await mutationResponse.json();

            if (mutationResponseData.errors) {
                console.error('GraphQL errors during mutation:', mutationResponseData.errors);
                return;
            }

            console.log('Mutation result:', mutationResponseData.data.createListFutureOpenPosition);
        } catch (error) {
            console.error('Error fetching open positions or creating future open positions:', error);
        }
    };

    
    

    const handleScenarioDelete = async (ids) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const graphqlUrl = `${apiUrl}/graphql/`;
        const token = localStorage.getItem('token');
    
        // Convertir les IDs en entiers si nécessaire
        const integerIds = ids.map((id) => parseInt(id, 10));
    
        const mutation = `
            mutation DeleteScenario($ids: [Int!]!) {
                deleteScenario(ids: $ids) {
                    success
                    message
                }
            }
        `;
    
        const variables = {
            ids: integerIds,
        };
    
        try {
            const response = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const responseData = await response.json();
    
            if (responseData.errors) {
                console.error('GraphQL errors:', responseData.errors);
                setMessage('Error deleting scenario(s).');
                return;
            }
    
            const { success, message } = responseData.data.deleteScenario;
    
            if (success) {
                // Mettre à jour la liste locale des scénarios
                fetchScenarios(); // Récupère la liste mise à jour des scénarios
                setMessage(message || 'Scenario(s) deleted successfully.');
            } else {
                setMessage(message || 'Failed to delete scenario(s).');
            }
        } catch (error) {
            console.error('Error deleting scenario(s):', error);
            setMessage('An error occurred while deleting the scenario(s).');
        }
    };
    
    const handleScenarioSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        const graphqlUrl = `${apiUrl}/graphql/`;
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('userData'));
        const entrepriseId = userData?.entreprise_id;
        if (!formData.name || !formData.budget || !formData.startDate || !formData.endDate) {
            setMessage('All fields are required.');
            return;
        }
    
        const mutation = isEditing
            ? `
                mutation UpdateScenario($id: Int!, $name: String!, $budget: Decimal!, $startDate: Date!, $endDate: Date!) {
                    updateScenario(id: $id, name: $name, budget: $budget, startDate: $startDate, endDate: $endDate) {
                        scenario {
                            id
                            name
                            budget
                            startDate
                            endDate
                        }
                    }
                }
            `
            : `
                mutation CreateScenario($name: String!, $budget: Decimal!, $entrepriseId: Int!, $startDate: Date!, $endDate: Date!) {
                    createScenario(entrepriseId: $entrepriseId, name: $name, budget: $budget, startDate: $startDate, endDate: $endDate) {
                        scenario {
                            id
                            name
                            budget
                            startDate
                            endDate
                        }
                    }
                }
            `;
    
        const variables = isEditing
            ? {
                  id:parseInt(editingScenarioId, 10), 
                  name: formData.name,
                  budget: parseFloat(formData.budget),
                  startDate: formData.startDate,
                  endDate: formData.endDate,
              }
            : {
                  name: formData.name,
                  budget: parseFloat(formData.budget),
                  entrepriseId,
                  startDate: formData.startDate,
                  endDate: formData.endDate,
              };
    
        try {
            const response = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const responseData = await response.json();
    
            if (responseData.errors) {
                console.error('GraphQL errors:', responseData.errors);
                setMessage(isEditing ? 'Error updating scenario.' : 'Error creating scenario.');
                return;
            }
    
            const newScenario = responseData.data[isEditing ? 'updateScenario' : 'createScenario'].scenario;
    
            // Step 2: If reuseOpenPositions is checked, fetch and create FutureOpenPositions
            if (!isEditing && formData.reuseOpenPositions) {
                await fetchAndCreateFutureOpenPositions(newScenario.id, entrepriseId);
            }
    
            setScenarios((prevScenarios) =>
                isEditing ? prevScenarios.map((s) => (s.id === editingScenarioId ? newScenario : s)) : [...prevScenarios, newScenario]
            );
    
            setMessage(isEditing ? 'Scenario updated successfully!' : 'Scenario created successfully!');
            setIsModalOpen(false);
            setFormData({
                name: '',
                budget: '',
                reuseOpenPositions: false,
                startDate: '',
                endDate: '',
            });
    
            if (!isEditing) {
                navigate(`/headcount/${newScenario.id}`);
            }
        } catch (error) {
            console.error('Error creating scenario:', error);
            setMessage(isEditing ? 'An error occurred while updating the scenario.' : 'An error occurred while creating the scenario.');
        }
    };
    

 
    

    const createFutureOpenPosition = async (scenarioId, position) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const graphqlUrl = `${apiUrl}/graphql/`;
        const token = localStorage.getItem('token');

        const mutation = `
            mutation CreateFutureOpenPosition(
                $scenarioId: Int!,
                $job: String!,
                $entrepriseId: Int!,
                $teamId: Int,
                $contactManagerId: Int,
                $salaryCharged: Decimal,
                $arrivalMonth: Date,
                $originalOpenPositionId:  Int
            ) {
                createFutureOpenPosition(
                    scenarioId: $scenarioId,
                    job: $job,
                    entrepriseId: $entrepriseId,
                    teamId: $teamId,
                    contactManagerId: $contactManagerId,
                    salaryCharged: $salaryCharged,
                    arrivalMonth: $arrivalMonth,
                    originalOpenPositionId:  $originalOpenPositionId
                ) {
                    futureOpenPosition {
                        id
                        job
                    }
                }
            }
        `;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const entrepriseId = userData?.entreprise_id;
        const variables = {
            scenarioId: parseInt(scenarioId, 10), // Conversion en entier
            job: position.job,
            entrepriseId: parseInt(entrepriseId, 10), // Conversion en entier
            teamId: position.team?.id ? parseInt(position.team.id, 10) : null, // Conversion en entier
            contactManagerId: position.contactManager?.id ? parseInt(position.contactManager.id, 10) : null, // Conversion en entier
            salaryCharged: position.salaryMax || null,
            arrivalMonth: position.arrivalMonth || null,
            originalOpenPositionId: parseInt(position.id, 10) || null,
        };
        

        try {
            const response = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.errors) {
                console.error('GraphQL errors:', responseData.errors);
            }
        } catch (error) {
            console.error('Error creating future open position:', error);
        }
    };

    const handleDuplicateScenario = async (scenarioId) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const graphqlUrl = `${apiUrl}/graphql/`;
        const token = localStorage.getItem('token');

        const mutation = `
            mutation DuplicateScenario($scenarioId: Int!) {
                duplicateScenario(scenarioId: $scenarioId) {
                    newScenario {
                        id
                        name
                        budget
                        startDate
                        endDate 
                    }
                    duplicatedPositions {
                        id
                        job
                    }
                }
            }
        `;

        const variables = { scenarioId: parseInt(scenarioId, 10) };


        try {
            const response = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.errors) {
                console.error('GraphQL errors:', responseData.errors);
                setMessage('Error duplicating scenario.');
                return;
            }

            const duplicatedScenario = responseData.data.duplicateScenario.newScenario;
            setScenarios((prevScenarios) => [...prevScenarios, duplicatedScenario]);
            setMessage('Scenario duplicated successfully!');
        } catch (error) {
            console.error('Error duplicating scenario:', error);
            setMessage('An error occurred while duplicating the scenario.');
        }
    };



    const handleEditScenario = (id) => {
        const scenario = scenarios.find((s) => s.id === id);
        if (scenario) {
            setFormData({ name: scenario.name, budget: scenario.budget, reuseOpenPositions: false , startDate: scenario.startDate, endDate: scenario.endDate });
            setEditingScenarioId(id);
            setIsEditing(true);
            setIsModalOpen(true);
        }
    };
    const resetFormData = () => {
        setFormData({
            startDate: "",
            endDate: "",
            name: "",
            budget: "",
            reuseOpenPositions: false,
        });
    };
    
    return (
        <div className="container-home">
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className="line-container-10">
                    <div className="hc-display-line">
                        <h5>Headcount Scenarios</h5>
                        <button
                            type="submit"
                            className="create-scenario-button"
                            onClick={() => setIsModalOpen(true)}
                        >
                            Create Scenario
                        </button>
                    </div>
                 
                    <ScenariosTable
                        scenarios={scenarios}
                        onDeleteScenarios={(ids) => handleScenarioDelete(ids)}
                        onDuplicateScenarios={(ids) => handleDuplicateScenario(ids)}
                        onEditScenario={(id) => handleEditScenario(id)} // Ajouté pour l'édition
                    />
                </div>
            </div>
            {isModalOpen && (
                <HCForm
                startDate={formData.startDate}
                endDate={formData.endDate}
                name={formData.name}
                budget={formData.budget}
                reuseOpenPositions={formData.reuseOpenPositions}
                onInputChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                onCheckboxChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.checked })}
                onSubmit={(e) => handleScenarioSubmit(e, isEditing)}
                message={message}
                onClose={() => {
                    resetFormData(); // Réinitialise les données du formulaire
                    setIsModalOpen(false); // Ferme la modal
                }}                
                isEditing={isEditing}
            />
            )}
        </div>
    );
};

export default HCScenario;
