import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser, faPlusCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import amplitude from 'amplitude-js';
import '../styles/Header.css';

const Header = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const fetchUserDataAndEntreprises = async () => {
      let storedUserData = localStorage.getItem('userData');
      let userData = storedUserData ? JSON.parse(storedUserData) : {};

      if (!userData.username) {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        try {
          const response = await fetch(`${apiUrl}/api/employe-log/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
          });

          if (!response.ok) {
            throw new Error('Erreur de réseau ou réponse non valide');
          }

          userData = await response.json();
          localStorage.setItem('userData', JSON.stringify(userData));
        } catch (error) {
          console.error("Erreur lors de la récupération des données de l'utilisateur:", error);
          return;
        }
      }


      // Définir les user properties sur Amplitude
      amplitude.getInstance().setUserProperties({
        username: userData.username,
        entreprise_id: userData.entreprise_id,
        email: userData.email
      });
    };

    const manageLocalStorage = () => {
      const lastConnectionDate = localStorage.getItem('lastConnectionDate');
      if (lastConnectionDate) {
        const lastConnectionTime = Date.parse(lastConnectionDate);
        const currentTime = Date.now();
        const oneWeek = 7 * 24 * 60 * 60 * 1000; // 7 jours en millisecondes

        if (currentTime - lastConnectionTime > oneWeek) {
          const token = localStorage.getItem('token');
          if (token) {
            localStorage.setItem('token', token);
          }
          localStorage.setItem('lastConnectionDate', new Date().toISOString());
        }
      } else {
        localStorage.setItem('lastConnectionDate', new Date().toISOString());
      }
    };

    fetchUserDataAndEntreprises();
    manageLocalStorage();

    const hostname = window.location.hostname;
    const environment = hostname.includes('staging') ? 'staging' : 'production';
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    amplitude.getInstance().logEvent('PageViewed', {
      page: currentPath,
      username: ampData.username || 'unknown',
      entreprise_id: ampData.entreprise_id || 'unknown',
      environment
    });

  }, [currentPath]);

  return (
    <div className="header">
      <div className='header-left'>
        {currentPath !== '/' && (
          <button onClick={() => navigate(-1)} className="icon-button">
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </button>
        )}
      </div>

      <div className='header-right'>
        <FontAwesomeIcon onClick={() => navigate('/create/company')} className='user-info' icon={faPlusCircle} />
        <FontAwesomeIcon onClick={() => navigate('/create/company/reload')} className='user-info' icon={faDownload} />
        <div onClick={() => navigate('/user/infos')} className="user-info">
          <FontAwesomeIcon icon={faUser} />
        </div>
      </div>
    </div>
  );
};

export default Header;