import React, { useState, useEffect } from 'react';
import HCFilters from './HCFilters';

const FuturePositionsTable = ({ onAddPosition, futurePositions, equipes, selectedRows, onRowClick, onCheckboxChange }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [filteredPositions, setFilteredPositions] = useState(futurePositions); // Initialiser avec les données complètes
    const itemsPerPage = 15;

    // Mettre à jour `filteredPositions` lorsque `futurePositions` change
    useEffect(() => {
        setFilteredPositions(futurePositions);
    }, [futurePositions]);

    // Fonction pour appliquer les filtres
    const handleApplyFilters = (filters) => {
        if (Object.values(filters).every((value) => !value)) {
            setFilteredPositions(futurePositions); // Aucun filtre appliqué, afficher toutes les données
            return;
        }

        let filtered = [...futurePositions];

        // Filtrage par statut
        if (filters.status) {
            filtered = filtered.filter((position) => position.status === filters.status);
        }

        // Filtrage par équipe
   
        if (filters.teamId) {
            filtered = filtered.filter((position) => {
                const teamName = position.team?.nomEquipe || null;
                return teamName === filters.teamId; // Comparaison par nom
            });
        }
        
        // Filtrage par date
        if (filters.startDate && filters.endDate) {
            filtered = filtered.filter((position) => {
                const arrivalDate = new Date(position.arrivalMonth);
                return (
                    arrivalDate >= new Date(filters.startDate) &&
                    arrivalDate <= new Date(filters.endDate)
                );
            });
        }

        // Filtrage par plage de salaire
        if (filters.salaryMin || filters.salaryMax) {
            filtered = filtered.filter((position) => {
                const salary = parseFloat(position.salaryCharged || 0);
                return (
                    (!filters.salaryMin || salary >= parseFloat(filters.salaryMin)) &&
                    (!filters.salaryMax || salary <= parseFloat(filters.salaryMax))
                );
            });
        }

        setFilteredPositions(filtered);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedPositions = [...filteredPositions].sort((a, b) => {
        if (sortConfig.key) {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (sortConfig.key === 'team') {
                aValue = a.team?.nomEquipe || '';
                bValue = b.team?.nomEquipe || '';
            }

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });

    const paginatedPositions = sortedPositions.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredPositions.length / itemsPerPage);

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    return (
        <div className="table-container">
            <div className='display-line'>

                <button
                                    type="submit"
                                    onClick={onAddPosition} >
                                    Add open position
                        </button>
                <HCFilters equipes={equipes} onApplyFilters={handleApplyFilters} />
            </div>

            <table className="positions-table">
                <thead>
                    <tr>
                        <th>Select</th>
                        <th onClick={() => handleSort('arrivalMonth')}>Start date</th>
                        <th onClick={() => handleSort('job')}>Position title</th>
                        <th onClick={() => handleSort('team')}>Team</th>
                        <th onClick={() => handleSort('salaryCharged')}>Salary Charged</th>
                        <th onClick={() => handleSort('requestedBy')}>Requested by</th>
                        <th onClick={() => handleSort('status')}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedPositions.map((position) => (
                        <tr
                            key={position.id}
                            className={`clickable ${selectedRows.includes(position.id) ? 'selected' : ''}`}
                            onClick={() => onRowClick(position)}
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedRows.includes(position.id)}
                                    onChange={() => onCheckboxChange(position.id)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </td>
                            <td>
                                {position.arrivalMonth
                                    ? new Date(position.arrivalMonth).toLocaleDateString('en-US', {
                                        month: '2-digit',
                                        year: '2-digit',
                                    })
                                    : 'N/A'}
                            </td>
                            <td>{position.job}</td>
                            <td>{position.team?.nomEquipe || 'N/A'}</td>
                            <td>
                                {parseFloat(position.salaryCharged).toLocaleString('fr-FR', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}{' '}€
                            </td>
                            <td>{position.requestedBy?.firstName}</td>
                            <td>
                                <div className={`status-cell ${position.status.toLowerCase()}`}>
                                    {position.status}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="hc-pagination-control">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default FuturePositionsTable;
