import React, { useState } from "react";
import GraphMonthlyView from "./GraphMonthlyView";

const KPISection = ({ scenario_name, masseSalarialeChargee, nombrePositions, budget, scenarioId }) => {
    // État pour contrôler la vue affichée (KPI ou Graph)
    const [isGraphView, setIsGraphView] = useState(false);
    const [isClosing, setIsClosing] = useState(false); // État pour indiquer si l'action est en cours
    const [isClosed, setIsClosed] = useState(false); // État pour confirmer la clôture

    // Calcul de la différence en pourcentage
    const percentageDiff = Math.round(((masseSalarialeChargee - budget) / budget) * 100);

    // Fonction pour basculer entre les vues
    const toggleView = () => {
        setIsGraphView(!isGraphView);
    };

    // Fonction pour gérer la clôture du scénario
    const handleCloseForecast = async () => {
        setIsClosing(true); // Activer l'état de chargement
    
        const apiUrl = process.env.REACT_APP_API_URL;
        const graphqlUrl = `${apiUrl}/graphql/`;
        const token = localStorage.getItem("token");
    
        const mutation = `
            mutation CreateOpenPositionsFromScenario($scenarioId: Int!) {
                createOpenPositionsFromScenario(scenarioId: $scenarioId) {
                    createdOpenPositions {
                        id
                        job
                        team {
                            id
                            nomEquipe
                        }
                        salaryMax
                        arrivalMonth
                        status
                    }
                    deletedOpenPositions {
                        id
                        job
                    }
                }
            }
        `;
    
        const variables = {
            scenarioId: parseInt(scenarioId, 10), // Convertir en entier
        };
    
        try {
            const response = await fetch(graphqlUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Inclure le token pour l'authentification
                },
                body: JSON.stringify({
                    query: mutation,
                    variables,
                }),
            });
    
            const result = await response.json();
    
            if (result.errors) {
                throw new Error(result.errors[0].message);
            }
    
            if (result.data.createOpenPositionsFromScenario) {
                setIsClosed(true); // Scénario clôturé avec succès
                alert("Le scénario a été clôturé avec succès !");
            } else {
                throw new Error("La mutation n'a pas retourné les données attendues.");
            }
        } catch (error) {
            console.error("Erreur :", error);
            alert("Impossible de clôturer le scénario. Réessayez plus tard.");
        } finally {
            setIsClosing(false); // Désactiver l'état de chargement
        }
    };
    

    return (
        <div className="line-container-10">
            <div className="display-line" style={{ alignItems: "center" }}>
                <h5>{scenario_name}</h5>

                <button className="btn-secondary" onClick={toggleView}>
                    {isGraphView ? "Show KPI View" : "Show Monthly View"}
                </button>

                <button
                    type="submit"
                    className="btn-primary"
                    onClick={handleCloseForecast}
                    disabled={isClosing || isClosed} // Désactiver le bouton pendant le chargement ou si déjà fermé
                >
                    {isClosing ? "Closing..." : isClosed ? "Closed" : "Close the forecast"}
                </button>
            </div>

            {/* Affichage conditionnel des vues */}
            {!isGraphView ? (
                <div className="display-line-flex">
                    {/* Vue KPI */}
                    <div className="card-kpi-staff">
                        <h5>Recruitment Forecast</h5>
                        <p>
                            {parseFloat(masseSalarialeChargee).toLocaleString("fr-FR", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                            {" €"}
                            <span
                                style={{
                                    marginLeft: "10px",
                                    color: percentageDiff > 0 ? "red" : "green",
                                    backgroundColor: percentageDiff > 0 ? "#ffe6e6" : "#e6ffe6",
                                    padding: "2px 6px",
                                    borderRadius: "10px",
                                    fontWeight: "bold",
                                }}
                            >
                                {percentageDiff > 0 ? `+${percentageDiff}%` : `${percentageDiff}%`}
                            </span>
                        </p>
                    </div>

                    <div className="card-kpi-staff">
                        <h5>Recruitment Budget</h5>
                        <p>
                            {parseFloat(budget).toLocaleString("fr-FR", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                            {" €"}
                        </p>
                    </div>

                    <div className="card-kpi-staff">
                        <h5>Open Position Number</h5>
                        <p>{nombrePositions}</p>
                    </div>
                </div>
            ) : (
                // Vue Graph
                <GraphMonthlyView scenarioId={scenarioId} />
            )}
        </div>
    );
};

export default KPISection;
