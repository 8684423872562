import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faColumns, faSearch } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Tableau.css';
import '../../styles/Button.css';

const Tableau = ({
    data,
    columns,
    visibleColumns,
    setVisibleColumns,
    sortConfig,
    onSort,
    onRowClick,
    updateDates,
    onUpdateClick,
    pageKey,
    hideFilter = false,
    hideSelect = false,
    onDuplicateClick,
    onDeleteClick,
    deleteId,
    onStatusChange,
}) => {
    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const [showSearch, setShowSearch] = useState(false); // Nouvelle variable d'état pour la visibilité de la recherche
    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem(`filters_${pageKey}`);
        return savedFilters ? JSON.parse(savedFilters) : [];
    });
    const [currentFilter, setCurrentFilter] = useState({ column: '', value: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const tableRef = useRef(null);
    const [editingCell, setEditingCell] = useState({ rowId: null, columnKey: null });
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        localStorage.setItem(`filters_${pageKey}`, JSON.stringify(filters));
    }, [filters, pageKey]);

    const getUniqueValues = (columnKey) => {
        const uniqueValues = data
            .map(item => item[columnKey])
            .filter((value, index, self) => self.indexOf(value) === index);
        return uniqueValues;
    };

    const statusOptions = [
        '0.Not Started',
        '1.Job Opened',
        '6.Offer Accepted',
        '7.Arrived',
    ];

    const getSortIcon = (key) => {
        if (!sortConfig || sortConfig.key !== key) return faSort;
        return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    };

    const handleSort = (key) => {
        onSort(key);
    };

    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value) {
            setFilters([...filters, currentFilter]);
            setCurrentFilter({ column: '', value: '' });
        }
    };

    const handleRemoveFilter = (index) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setCurrentFilter({ ...currentFilter, [name]: value });
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value).replace(/\s/g, ' ').replace(/,/g, ' ');
    };

    const formatDate = (value) => {
        if (value == null) {
            return '';
        }
        const date = new Date(value);
        if (!isNaN(date)) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        } else {
            return 'A définir';
        }
    };

    const getFormattedValue = (key, value) => {
        if (key === 'budget' || key === 'salary_max') {
            return formatCurrency(value);
        }
        if (key === 'last_update' || key === 'arrival_month' || key === 'job_opening_date' || key === 'FORECAST_arrival_month' || key === 'FORECAST_job_opening_date' || key === 'FORECAST_arrival_month_init') {
            return formatDate(value);
        }
        return value;
    };

    const getCellColorClass = (key, value) => {
        if (key === 'status') {
            switch (value) {
                case '0.Not Started':
                    return 'status-not-started';
                case '1.Job Opened':
                    return 'status-job-opened';
                case '2.Screening':
                    return 'status-screening';
                case '3.Interviewing':
                    return 'status-interviewing';
                case '4.Interviewing 2':
                    return 'status-interviewing-2';
                case '5.Offer Extended':
                    return 'status-offer-extended';
                case '6.Offer Accepted':
                    return 'status-offer-accepted';
                case '7.Arrived':
                    return 'status-arrived';
                default:
                    return '';
            }
        }
        return '';
    };

    const getPercentageFillStyle = (key, value) => {
        if (key.includes('percentage') && typeof value === 'number') {
            const roundedValue = Math.round(value / 10) * 10;
            return {
                background: `linear-gradient(to right, #EBA49B40 ${roundedValue}%, transparent ${roundedValue}%)`,
            };
        }
        return {};
    };

    const handleColumnVisibilityChange = (columnKey) => {
        setVisibleColumns((prevColumns) => {
            const updatedColumns = prevColumns.includes(columnKey)
                ? prevColumns.filter((key) => key !== columnKey)
                : [...prevColumns, columnKey];
            let pageUrl = window.location.pathname;
            let baseUrl = pageUrl.match(/\/[^\/]+\/[^\/]+/)[0];
            localStorage.setItem(`visibleColumns_${baseUrl}`, JSON.stringify(updatedColumns));
            return updatedColumns;
        });
    };

    const startResizing = (event, columnKey) => {
        const startX = event.clientX;
        const startWidth = event.target.parentElement.offsetWidth;

        const handleMouseMove = (moveEvent) => {
            const newWidth = startWidth + (moveEvent.clientX - startX);
            if (newWidth > 100) {
                event.target.parentElement.style.width = `${newWidth}px`;
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const applyFilters = (data) => {
        let filteredData = data;

        filteredData = filters.reduce((filteredData, filter) => {
            return filteredData.filter(item => item[filter.column] === filter.value);
        }, filteredData);

        if (searchQuery) {
            const lowerCaseQuery = searchQuery.toLowerCase();

            filteredData = filteredData.filter(item => {
                return visibleColumns.some(columnKey => {
                    const columnValue = item[columnKey];
                    return columnValue && columnValue.toString().toLowerCase().includes(lowerCaseQuery);
                });
            });
        }

        return filteredData;
    };

    const filteredData = applyFilters(data);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    return (
        <div className="table-container">
            {!hideFilter && (
                <div className="card-full">
                    <h5>Filter by</h5>
                    <div className="display-line dropdown">
                        <select className="dropdown" name="column" onChange={handleFilterChange} value={currentFilter.column}>
                            <option value="">Select Column</option>
                            {columns.map((column) => (
                                <option key={column.key} value={column.key}>
                                    {column.label}
                                </option>
                            ))}
                        </select>
                        <select className="dropdown" name="value" onChange={handleFilterChange} value={currentFilter.value} disabled={!currentFilter.column}>
                            <option value="">Select Value</option>
                            {currentFilter.column && getUniqueValues(currentFilter.column).map((value, index) => (
                                <option key={index} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        <button type="submit" onClick={handleAddFilter} className="btn-secondary" disabled={!currentFilter.column || !currentFilter.value}>Add Filter</button>
                    </div>
                    <div className="display-line">
                        {filters.map((filter, index) => (
                            <div key={index} className="filter-tag">
                                {columns.find(column => column.key === filter.column)?.label}: {filter.value}
                                <button className="filter-tag-btn" type="button" onClick={() => handleRemoveFilter(index)}>x</button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
           
            <div className='card-full display-line'>
                {/* Bouton pour afficher/masquer la barre de recherche */}
                <div className="display-line">
                    <button className='icon-button' onClick={() => setShowSearch(!showSearch)}>
                        <FontAwesomeIcon icon={faSearch} />

                    </button>
                    <button className='icon-button' onClick={() => setShowColumnSelector(!showColumnSelector)}>
                            <FontAwesomeIcon icon={faColumns} />
                        </button>
                </div>

                {/* Barre de recherche affichée conditionnellement */}
                {showSearch && (
                    <div className="display-line">


                            <input
                                type="text"
                                placeholder="Rechercher..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="search-input"
                            />

                    </div>
                )}

                {!hideSelect && (
                    <div className="display-line">

                        {showColumnSelector && (
                            <form className="display-line">
                                {columns.map((column) => (
                                    <div className="display-line" key={column.key}>
                                        <label className='checkbox'>
                                            <input
                                                type="checkbox"
                                                checked={visibleColumns.includes(column.key)}
                                                onChange={() => handleColumnVisibilityChange(column.key)}
                                            />
                                            <span className={visibleColumns.includes(column.key) ? 'selected' : ''}>
                                                {column.label}
                                            </span>
                                        </label>
                                    </div>
                                ))}
                            </form>
                        )}
                    </div>
                )}
                
                <div className='table-container'>
                    <table className="table-custom" ref={tableRef}>
                        <thead>
                            <tr>
                                {columns
                                    .filter((column) => visibleColumns.includes(column.key))
                                    .map((column) => (
                                        <th key={column.key}>
                                            <div
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                onClick={() => handleSort(column.key)}
                                            >
                                                {column.label} <FontAwesomeIcon icon={getSortIcon(column.key)} />
                                                <div className="resizer" onMouseDown={(e) => startResizing(e, column.key)} />
                                            </div>
                                        </th>
                                    ))}
                                {onDuplicateClick && <th>Actions</th>}
                            </tr>
                        </thead>

                        <tbody>
                            {currentItems.map((item) => (
                                <tr className="clickable" key={item.id} onClick={() => onRowClick(item)}>
                                    {columns
                                        .filter((column) => visibleColumns.includes(column.key))
                                        .map((column) => (
                                            <td
                                                key={column.key}
                                                className={getCellColorClass(column.key, item[column.key])}
                                                style={getPercentageFillStyle(column.key, item[column.key])}
                                                onClick={(e) => {
                                                    if (column.key === 'status') {
                                                        e.stopPropagation();
                                                        setEditingCell({ rowId: item.id, columnKey: column.key });
                                                    }
                                                }}
                                            >
                                                {editingCell.rowId === item.id && editingCell.columnKey === column.key ? (
                                                    <select
                                                        value={item[column.key]}
                                                        onChange={(e) => {
                                                            const newStatus = e.target.value;
                                                            onStatusChange(item.id, newStatus);
                                                            setEditingCell({ rowId: null, columnKey: null });
                                                        }}
                                                        onBlur={() => setEditingCell({ rowId: null, columnKey: null })}
                                                    >
                                                        {statusOptions.map(status => (
                                                            <option key={status} value={status}>{status}</option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    column.render
                                                        ? column.render(item[column.key], item)
                                                        : getFormattedValue(column.key, item[column.key])
                                                )}
                                            </td>
                                        ))}
                                        {onDuplicateClick && (
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn-duplicate"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onDuplicateClick(item);
                                                    }}
                                                >
                                                    Duplicate
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn-delete"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Empêche le clic de propager l'événement au parent (ligne)
                                                        if (window.confirm('Are you sure you want to delete this item?')) {
                                                            onDeleteClick(item.id); // Appelle la fonction avec l'id de l'élément
                                                        }
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                            
                                        )}
                                            
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
                <div className="page-nav">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className='icon-button'
                    >
                        &lt; Previous
                    </button>
                    <span className="page-nav police">{currentPage}/{totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className='icon-button'
                    >
                        Next &gt;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Tableau;
