import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "../../styles/ScenariHR.css";

const HCForm = ({
    name,
    budget,
    startDate,
    endDate,
    reuseOpenPositions,
    onInputChange,
    onCheckboxChange,
    onSubmit,
    message,
    onClose,
    isEditing = false,
    scenarioId = null,
}) => {
    const modalRef = useRef(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        setTimeout(() => {
            onSubmit(e, isEditing, scenarioId);
        }, 1500); //
        // Appeler onSubmit avec les paramètres appropriés
       
    
        // Réinitialiser l'état après 3 secondes
        setTimeout(() => {
            setIsSubmitted(false);
        }, 3000); // 3000 ms = 3 secondes
    };
    

    return (
        <div className="modal-overlay" onClick={handleClickOutside}>
            <div className="modal-container" ref={modalRef}>
                <button className="modal-close" onClick={onClose}>
                    &times;
                </button>
                <h5>{isEditing ? "Edit Scenario" : "Create a New Scenario"}</h5>

                <form className="hc-form" onSubmit={handleSubmit}>
                    <div className="hc-form-line">
                        <label>Scenario Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={onInputChange}
                            required
                        />
                    </div>
                    <div className="hc-form-line">
                        <label>Budget:</label>
                        <input
                            type="number"
                            step="0.01"
                            name="budget"
                            value={budget}
                            onChange={onInputChange}
                            required
                        />
                    </div>
                    <div className="hc-form-line">
                        <label>Start date:</label>
                        <input
                            type="date"
                            name="startDate"
                            value={startDate}
                            onChange={onInputChange}
                            required
                        />
                    </div>
                    <div className="hc-form-line">
                        <label>End date:</label>
                        <input
                            type="date"
                            name="endDate"
                            value={endDate}
                            onChange={onInputChange}
                            required
                        />
                    </div>
                    {!isEditing && (
                        <div className="hc-form-line">
                            <label>
                                <input
                                    type="checkbox"
                                    name="reuseOpenPositions"
                                    checked={reuseOpenPositions}
                                    onChange={onCheckboxChange}
                                />
                                Reuse Current Open Positions
                            </label>
                        </div>
                    )}
                    <button type="submit">{isEditing ? "Save Changes" : "Create Scenario"}</button>
                </form>

                {/* Animation de validation */}
                {isSubmitted && (
                    <div className="validation-feedback">
                        <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                        <p>Submission successful!</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HCForm;
