import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import "../styles/Text.css";
import "../styles/Container.css";
import "../styles/Home.css";
import WorkforceNumberKPI from '../components/kpi/Recrutement/WorkforceNumberKPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
  const [recruitingTeams, setRecruitingTeams] = useState([]);
  const [whatsNew, setWhatsNew] = useState([]);
  const [loadingWhatsNew, setLoadingWhatsNew] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Récupérer userData depuis localStorage
    const storedUserData = localStorage.getItem('userData');
    const userData = storedUserData ? JSON.parse(storedUserData) : null;

    if (!userData || !userData.entreprise_id) {
      console.error('Entreprise ID is missing in userData');
      return;
    }

    const entrepriseId = userData.entreprise_id;

    // Fetch recruiting teams data
    const fetchRecruitingTeams = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const groupUrl = `${apiUrl}/openposition/group_by_team/`;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(groupUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();

        // Sort the data by number of open positions in descending order
        const sortedData = data.sort((a, b) => b.num_open_positions - a.num_open_positions);

        // Take the top 4 teams
        const topTeams = sortedData.slice(0, 4);

        setRecruitingTeams(topTeams);
      } catch (error) {
        console.error('Error fetching recruiting teams:', error);
      }
    };

    // Fetch "What's new?" data
    const fetchWhatsNew = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const whatsNewUrl = `${apiUrl}/graphql/`; // Point d'entrée GraphQL
      const token = localStorage.getItem('token');

      const query = `
        query ($entrepriseId: Int!) {
          openPositions(entrepriseId: $entrepriseId) {
            id
            job
            lastUpdate
            lastUpdatedBy {
              firstName
              lastName
            }
          }
        }
      `;

      try {
        const response = await fetch(whatsNewUrl, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            query,
            variables: { entrepriseId } // Utiliser entrepriseId depuis userData
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const { data } = await response.json();

        // Sort by lastUpdate in descending order and take the top 5 updates
        const sortedUpdates = data.openPositions
          .sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate))
          .slice(0, 5);

        setWhatsNew(sortedUpdates);
      } catch (error) {
        console.error('Error fetching "What\'s new?":', error);
      } finally {
        setLoadingWhatsNew(false);
      }
    };

    fetchRecruitingTeams();
    fetchWhatsNew();
  }, []);

  const handleTeamClick = (teamId) => {
    navigate(`/staffingplan/team/${teamId}`);
  };

  return (
    <div className='container-home'>
      <Sidebar />
      <div className="container-right">
        <Header />

        <div className='line-container-10'>
          <h5>Overview</h5>
        </div>
        <div className='cards-container'>
          <WorkforceNumberKPI />
          <div className='team-card'>
            <h5>Open positions (filled/total)</h5>
            <div className='team-container'>
              {recruitingTeams.map(team => (
                <div key={team.id} className='team-line' onClick={() => handleTeamClick(team.id)}>
                  <p>{team.team}</p>
                  <div className='team-kpi'>
                    <p>{team.num_open_positions_filled}/{team.num_open_positions}</p>
                  </div>
                </div>
              ))}
            </div>
            <button className='btn-secondary' onClick={() => navigate('/staffingplan')}>View All</button>
          </div>
        </div>

        <div className='card-half-full'>
          <h5>What's new?</h5>
          {loadingWhatsNew ? (
            <div className="loader-icon"><FontAwesomeIcon icon={faSpinner} spin /></div>
          ) : whatsNew.length > 0 ? (
            <>
              <table className="positions-table">
                <thead>
                  <tr>
                    <th>Job</th>
                    <th>Last Updated By</th>
                    <th>Last Update</th>
                  </tr>
                </thead>
                <tbody>
                  {whatsNew.map((item, index) => (
                    <tr key={index}>
                      <td>{item.job}</td>
                      <td>{item.lastUpdatedBy?.firstName} {item.lastUpdatedBy?.lastName}</td>
                      <td>{new Date(item.lastUpdate).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className='btn-secondary' onClick={() => navigate('/staffingplan')}>
                View all
              </button>
            </>
          ) : (
            <p>No recent updates.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
