import React, { useState, useRef, useEffect } from 'react';
import '../../styles/ChatBotComponent.css';
import chatIcon from '../../assets/chaticone.png';

const anecdotes = [
    "Did you know? The first headcount planning tool was probably a chalkboard!",
    "Headcount planning is like Tetris, except the pieces are people and they move themselves!",
    "Why did the HR manager bring a ladder to the office? To reach the headcount goals!",
    "Planning headcount is like forecasting the weather—sometimes you just hope for the best.",
    "Headcount planning: where math meets magic and budgets meet reality.",
    "Did you hear about the HR planner who became a detective? They loved finding missing people.",
    "Headcount planning is like a puzzle, except the pieces keep changing shapes!",
    "Why do headcount planners love coffee? Because every head needs a strong body to carry it!",
    "Hiring is like dating—sometimes you swipe right on a superstar.",
    "Headcount planning: because cloning isn’t legal yet.",
    "Why did the HR team go to the comedy club? To hire people who could ‘crack’ the team up.",
    "Headcount planning: when you realize people are more unpredictable than spreadsheets.",
    "Why did the HR manager bring a crystal ball to the meeting? For workforce forecasting!",
    "Headcount planning: the only place where over-budget sounds like a person problem.",
    "If headcount planning were easy, everyone would be doing it with sticky notes.",
    "Why did the HR planner carry a calculator? To count heads, not tails!",
    "Headcount planning: solving the Rubik's cube of team dynamics.",
    "HR planners are like architects, but their buildings are teams.",
    "Headcount planning is proof that time travel isn’t real—otherwise, we’d fix next year’s mistakes!",
    "Why don’t headcount planners take vacations? Because budgets don’t take breaks!"
];

const ChatBotComponent = ({ scenarioId, onUpdatePositions }) => {
    const [userMessage, setUserMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isFirstInteraction, setIsFirstInteraction] = useState(true);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const entrepriseId = userData ? userData.entreprise_id : null;

    const chatEndRef = useRef(null);

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatHistory, isTyping]);

    const addBotMessage = (message, delay = 0) => {
        return new Promise((resolve) => {
            setIsTyping(true);
            setTimeout(() => {
                setIsTyping(false);
                setChatHistory((prevHistory) => [
                    ...prevHistory,
                    { sender: 'bot', message },
                ]);
                resolve();
            }, delay);
        });
    };

    const handleChatOpen = async () => {
        setIsChatOpen(true);
        if (isFirstInteraction) {
            // Ajouter un message d'introduction
            await addBotMessage(
                "Hi! I'm here to help you with headcount planning in the simplest way possible. Feel free to ask me anything!",
                500
            );
            
            // Sélectionner et afficher une anecdote aléatoire
            const randomAnecdote = anecdotes[Math.floor(Math.random() * anecdotes.length)];
            await addBotMessage(randomAnecdote, 2000);

            setIsFirstInteraction(false);
        }
    };

    const sendMessage = async () => {
        if (!userMessage.trim()) {
            return;
        }

        const newChatHistory = [
            ...chatHistory,
            { sender: 'user', message: userMessage },
        ];
        setChatHistory(newChatHistory);
        setUserMessage('');
        setIsLoading(true);

        try {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_API_URL;

            const response = await fetch(`${apiUrl}/chatbot/${scenarioId}/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: userMessage,
                    entreprise_id: entrepriseId,
                }),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la communication avec le serveur');
            }

            const data = await response.json();

            // Afficher la réponse du bot
            await addBotMessage(data.response_chat, 1500);

            // Appeler la fonction de mise à jour si nécessaire
            onUpdatePositions();
        } catch (error) {
            console.error('Erreur:', error);
            await addBotMessage('Erreur de communication avec le serveur.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="chat-bot-wrapper">
            {!isChatOpen && (
                <button
                    className="chat-bot-toggle-button"
                    onClick={handleChatOpen}
                >
                    <img src={chatIcon} alt="Chat" className="chat-icon" />
                </button>
            )}

            {isChatOpen && (

                <div className={`chat-bot-container ${!isChatOpen ? 'hidden' : ''}`}>



                    <div className="chat-header">
                        <img src={chatIcon} alt="Chat" className="chat-icon-little" />
                        <span>Polarus</span>
                        <button
                            className="chat-close-button"
                            onClick={() => setIsChatOpen(false)}
                        >
                            ✖
                        </button>
                    </div>
                    <div className="chat-history">
                        {chatHistory.map((chat, index) => (
                            <div
                                key={index}
                                className={chat.sender === 'user' ? 'chat-user' : 'chat-bot'}
                            >
                                {chat.message}
                            </div>
                        ))}
                        {isTyping && (
                            <div className="chat-bot typing-animation">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        )}
                        <div ref={chatEndRef} />
                    </div>
                    <div className="chat-input">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask Polarus to help you..."
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !isLoading) {
                                    sendMessage();
                                }
                            }}
                        />
                        <button onClick={sendMessage} disabled={isLoading}>
                            Ask
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatBotComponent;
