import React, { useEffect, useState } from 'react';
import "../../styles/Home.css";
import "../../styles/List.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Team = () => {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    const fetchTeams = async () => {
        const graphqlUrl = `${apiUrl}/graphql/`;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const entrepriseId = parseInt(userData?.entreprise_id, 10);

        if (!entrepriseId || isNaN(entrepriseId)) {
            setError('Valid Entreprise ID not found in user data.');
            setLoading(false);
            return;
        }

        const query = `
         query GetTeams($entrepriseId: Int!) {
                equipes(entrepriseId: $entrepriseId) {
                    id
                    nomEquipe
                    description
                    managers {
                        id
                        firstName
                    }
                    recruiters {
                        id
                        firstName
                    }
                }
        }`;

        const variables = { entrepriseId };

        try {
            const response = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ query, variables }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.errors) {
                console.error('GraphQL errors:', data.errors);
                setError('Failed to fetch team data.');
                return;
            }

            setTeams(data.data.equipes);
        } catch (err) {
            console.error('Error fetching team data:', err);
            setError('An error occurred while fetching teams.');
        } finally {
            setLoading(false);
        }
    };

    const removeManagerOrRecruiter = async (teamId, userId, role) => {
        const graphqlUrl = `${apiUrl}/graphql/`;
        const mutation = `
            mutation UpdateTeam($id: Int!, $managers: [Int!], $recruiters: [Int!]) {
                updateEquipe(id: $id, managers: $managers, recruiters: $recruiters) {
                    equipe {
                        id
                        managers {
                            id
                            firstName
                        }
                        recruiters {
                            id
                            firstName
                        }
                    }
                }
            }
        `;

        const team = teams.find(t => t.id === teamId);
       
        const updatedManagers = role === 'manager'
        ? team.managers.filter(m => m.id !== userId).map(m => parseInt(m.id, 10))
        : team.managers.map(m => parseInt(m.id, 10));
        const updatedRecruiters = role === 'recruiter'
        ? team.recruiters.filter(r => r.id !== userId).map(r => parseInt(r.id, 10))
        : team.recruiters.map(r => parseInt(r.id, 10));
    

        const variables = {

            id: parseInt(teamId, 10), 
            managers: updatedManagers,
            recruiters: updatedRecruiters,
        };

        try {
            const response = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ query: mutation, variables }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            if (data.errors) {
                console.error('GraphQL errors:', data.errors);
                setError('Failed to update team.');
                return;
            }

            // Update local state with new managers/recruiters
            setTeams(teams.map(t => (t.id === teamId ? data.data.updateEquipe.equipe : t)));
        } catch (err) {
            console.error('Error updating team:', err);
            setError('An error occurred while updating the team.');
        }
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    return (
        <div className='card-full'>
            <h3>Teams</h3>

            {loading && (
                <div>
                    <FontAwesomeIcon icon={faSpinner} spin /> Loading teams...
                </div>
            )}

            {error && <p className="error">{error}</p>}

            {!loading && !error && (
                <ul className="team-list">
                    {teams.map(team => (
                        <li key={team.id} className="team-item">
                            <h3>{team.nomEquipe}</h3>
                            <p>{team.description}</p>
                            <div>
                                <strong>Managers:</strong>
                                <ul>
                                    {team.managers.map(manager => (
                                        <li key={manager.id}>
                                            {manager.firstName}
                                            <button onClick={() => removeManagerOrRecruiter(team.id, manager.id, 'manager')}>
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <strong>Recruiters:</strong>
                                <ul>
                                    {team.recruiters.map(recruiter => (
                                        <li key={recruiter.id}>
                                            {recruiter.firstName}
                                            <button onClick={() => removeManagerOrRecruiter(team.id, recruiter.id, 'recruiter')}>
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Team;
